// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/travis/build/TECHMAUS/Classicurls.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/home/travis/build/TECHMAUS/Classicurls.com/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/home/travis/build/TECHMAUS/Classicurls.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("/home/travis/build/TECHMAUS/Classicurls.com/src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-speellijst-js": () => import("/home/travis/build/TECHMAUS/Classicurls.com/src/pages/speellijst.js" /* webpackChunkName: "component---src-pages-speellijst-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/travis/build/TECHMAUS/Classicurls.com/.cache/data.json")

